import React, { useEffect, useState, useMemo } from 'react';

import { Title } from '../../components/Title';
import ExpandMoreLessButton from '../../components/ExpandMoreLessButton';
import apiCallsService from '../../services/apiCalls.service';
import TablePage from '../../styledComponents/pages/TablePage';

import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';


const PLN_CURRENCY_CODE = 'PLN';
const monthOrder = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const escapeCsv = (str) => {
	const stringValue = String(str || '');
	if (stringValue.includes(',') || stringValue.includes('"') || stringValue.includes('\n')) {
		return `"${stringValue.replace(/"/g, '""')}"`;
	}
	return stringValue;
};


export const AdAccountsMonthlySpend = () => {
    const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');

	const [tableData, setTableData] = useState([]);
	const [filteredTableData, setFilteredTableData] = useState([]);

	const [searchByNameQuery, setSearchByNameQuery] = useState('');

	const [sortingByMonth, setSortingByMonth] = useState(null);
	const [sortingDirection, setSortingDirection] = useState(false);

    const fetchAdAccountsMetadata = async () => {
		setLoading(true);
		setError('');

		try {
			const response = await apiCallsService.getMonthlySpendByAdAccount();

			if (response.error) {
				setError(response.error);
				setTableData([]);
				setFilteredTableData([]);

				return;
			}

			setTableData(response || []);
		} catch (err) {
			setError('Failed to fetch data');
			setTableData([]);
			setFilteredTableData([]);
		} finally {
			setLoading(false);
		}
	};

    useEffect(() => {
        let result = [...tableData];
		
        if (searchByNameQuery) {
            result = result.filter(record =>
                record.adAccountName.toLowerCase().includes(searchByNameQuery.toLowerCase())
            );
        }
		
        if (sortingByMonth) {
            result = result.sort((a, b) => {
                const aMonthData = a.monthlySpend.find(ms => ms.month === sortingByMonth);
                const bMonthData = b.monthlySpend.find(ms => ms.month === sortingByMonth);

                const aValue = aMonthData?.totalSpend || 0;
                const bValue = bMonthData?.totalSpend || 0;

                return sortingDirection ? aValue - bValue : bValue - aValue;
            });
        }

        setFilteredTableData(result);

    }, [tableData, sortingDirection, sortingByMonth, searchByNameQuery]);

    useEffect(() => {
        fetchAdAccountsMetadata();
    }, []);

    const handleSortingBy = month => {
		if (month === sortingByMonth) {
			setSortingDirection(!sortingDirection);
		} else {
			setSortingByMonth(month);
			setSortingDirection(false);
		}
	};

    const stylesTableHead = month => ({
        color: sortingByMonth === month ? 'rgba(0, 0, 0, 1)' : 'rgba(0, 0, 0, 0.6)',
        cursor: 'pointer',
        whiteSpace: 'nowrap'
    });

    const allMonthsYears = useMemo(() => {
		if (!tableData || tableData.length === 0) return [];

		return [...new Set(tableData.flatMap(account => account.monthlySpend.map(spend => `${spend.month}-${spend.year}`)))]
			.sort((a, b) => {
				const [aMonth, aYear] = a.split('-');
				const [bMonth, bYear] = b.split('-');
				const aMonthIndex = monthOrder.indexOf(aMonth);
				const bMonthIndex = monthOrder.indexOf(bMonth);
				if (aYear !== bYear) {
					return parseInt(bYear, 10) - parseInt(aYear, 10);
				} else {
					return bMonthIndex - aMonthIndex;
				}
			});
	}, [tableData]);

    const monthlySumsUSD_FilteredData = useMemo(() => {
        const sums = {};
        allMonthsYears.forEach(monthYear => {
            const [month, year] = monthYear.split('-');
            let total = 0;
            filteredTableData.forEach(account => {
                if (account.currency !== PLN_CURRENCY_CODE) {
                    const monthSpend = account.monthlySpend.find(spend => spend.month === month && spend.year === year);
                    total += monthSpend ? Number(monthSpend.totalSpend) : 0;
                }
            });
            sums[monthYear] = total;
        });
        return sums;
    }, [filteredTableData, allMonthsYears]);

	const handleDownloadPreviousMonthCategorized = () => {
		if (!tableData.length) return;

		const now = new Date();
		const currentMonth = now.getMonth();
		const currentYear = now.getFullYear();

		let previousMonthIndex = currentMonth - 1;
		let previousYear = currentYear;

		if (previousMonthIndex < 0) {
			previousMonthIndex = 11;
			previousYear = currentYear - 1;
		}

		const previousMonthName = monthOrder[previousMonthIndex];
        const previousMonthYearStr = String(previousYear);

        console.log(`Downloading data for: ${previousMonthName} ${previousYear}`);
		const categories = {
			'GC-LA': { accounts: [], sumUSD: 0 },
			'GC-UTC': { accounts: [], sumUSD: 0 },
			'Amus': { accounts: [], sumUSD: 0 },
			'Other': { accounts: [], sumUSD: 0 },
		};

		tableData.forEach(account => {
			const prevMonthSpendData = account.monthlySpend.find(
				spend => spend.month === previousMonthName && String(spend.year) === previousMonthYearStr
			);

			if (prevMonthSpendData) {
				const nameLower = account.adAccountName.toLowerCase();
				const spendValue = Number(prevMonthSpendData.totalSpend) || 0;
				const currencySymbol = account.currency === PLN_CURRENCY_CODE ? 'zł' : '$';
				const isUSD = account.currency !== PLN_CURRENCY_CODE;

                const accountInfo = {
                    name: account.adAccountName,
                    spendFormatted: `${spendValue.toFixed(2)} ${currencySymbol}`
                };

				let categoryKey = 'Other';

				if (nameLower.includes('gc-la') || nameLower.startsWith('gc-la-')) {
					categoryKey = 'GC-LA';
				} else if (nameLower.includes('gc-utc') || nameLower.startsWith('gc-utc-')) {
                     categoryKey = 'GC-UTC';
                } else if (nameLower.startsWith('amus_')) {
					categoryKey = 'Amus';
				}

				categories[categoryKey].accounts.push(accountInfo);
				if (isUSD) {
					categories[categoryKey].sumUSD += spendValue;
				}
			}
		});

		const headerRow = [
			`GC-LA Account (${previousMonthName} ${previousYear})`, `Spend`,
			`GC-UTC Account (${previousMonthName} ${previousYear})`, `Spend`,
			`Amus Account (${previousMonthName} ${previousYear})`, `Spend`,
            `Other Account (${previousMonthName} ${previousYear})`, `Spend`,
		];
		let csvContent = "data:text/csv;charset=utf-8," + headerRow.map(escapeCsv).join(",") + "\n";

		const maxLength = Math.max(
			categories['GC-LA'].accounts.length,
			categories['GC-UTC'].accounts.length,
			categories['Amus'].accounts.length,
            categories['Other'].accounts.length
		);

		for (let i = 0; i < maxLength; i++) {
			const row = [];

            for (const key of ['GC-LA', 'GC-UTC', 'Amus', 'Other']) {
                const account = categories[key].accounts[i];
                row.push(escapeCsv(account ? account.name : ''));
                row.push(escapeCsv(account ? account.spendFormatted : ''));
            }

			csvContent += row.join(",") + "\n";
		}

        const summRow = [];

        for (const key of ['GC-LA', 'GC-UTC', 'Amus', 'Other']) {
            summRow.push(escapeCsv('SUMM (USD)')); // Label for the category sum
            const formattedSum = `${categories[key].sumUSD.toFixed(2)} $`;
            summRow.push(escapeCsv(formattedSum)); // The calculated sum
        }

        csvContent += summRow.join(",") + "\n";

		const encodedUri = encodeURI(csvContent);
		const link = document.createElement("a");

		link.setAttribute("href", encodedUri);
		link.setAttribute("download", `previous_month_spend_${previousMonthName}_${previousYear}.csv`);
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	return (
		<>
			<Title title="Ad Accounts Monthly Spend" />
			<TablePage>
				{loading ? (
					<Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
						<CircularProgress size="4rem" />
					</Box>
				) : error ? (
					<Alert severity="error">Error: {error}</Alert>
				) : (
					<>
						<Box
							sx={{
								backgroundColor: '#f0f7fb',
								borderLeft: 'solid 4px #3498db',
								lineHeight: '1.5',
								overflow: 'hidden',
								padding: '12px 15px',
								textAlign: 'left',
								marginBottom: '20px'
							}}
						>
							<Typography color="text.secondary" variant="body2">
								Table displays monthly spend for each ad account. Use the search bar to filter by name and click month headers to sort. The 'SUMM' row shows the total USD spend for the currently displayed accounts in each month. Download button exports categorized spend for the previous month only.
							</Typography>
						</Box>

						<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', gap: 2 }}>
							<TextField
								sx={{ flexGrow: 1 }}
								label="Search by account name"
								variant="outlined"
								margin="none"
								value={searchByNameQuery}
								onChange={(e) => setSearchByNameQuery(e.target.value)}
							/>

							<Button
								variant="contained"
                                onClick={handleDownloadPreviousMonthCategorized}
								disabled={tableData.length === 0}
								startIcon={<DownloadIcon />}
							>
								Download Prev Month
							</Button>
						</Box>

                        <TableContainer sx={{ maxHeight: '70vh' }}>
							<Table stickyHeader>
								<TableHead>
									<TableRow>
										<TableCell rowSpan={2} sx={{ position: 'sticky', left: 0, zIndex: 1101, background: '#fff' }}>Account</TableCell>
										<TableCell colSpan={allMonthsYears.length} align="center">Monthly Spends</TableCell>
									</TableRow>
									<TableRow>
										{allMonthsYears.map((monthYear) => {
											const [month] = monthYear.split('-');
											return (
												<TableCell
													key={monthYear}
													sx={stylesTableHead(month)}
													onClick={() => handleSortingBy(month)}
													align="right"
												>
													{monthYear.replace('-', ' ')}
													<ExpandMoreLessButton
														enabled={sortingByMonth === month}
														sortingDirection={sortingDirection}
													/>
												</TableCell>
											);
										})}
									</TableRow>
								</TableHead>
								<TableBody>
									{filteredTableData.map(account => (
										<TableRow key={account.adAccountId} hover>
											<TableCell sx={{ position: 'sticky', left: 0, zIndex: 1000, background: '#fff', whiteSpace: 'nowrap' }}>
												{account.adAccountName}
											</TableCell>
											{allMonthsYears.map((monthYear) => {
												const [month, year] = monthYear.split('-');
												const monthSpend = account.monthlySpend.find(spend => spend.month === month && spend.year === year);
												const spendValue = monthSpend ? Number(monthSpend.totalSpend) : 0;
												const currencySymbol = account.currency === PLN_CURRENCY_CODE ? 'zł' : '$';
												return (
													<TableCell key={monthYear} align="right">
														{spendValue.toFixed(2)} {currencySymbol}
													</TableCell>
												);
											})}
										</TableRow>
									))}

									{filteredTableData.length > 0 && (
										<TableRow sx={{ '& th, & td': { fontWeight: 'bold', borderTop: '2px solid black' } }}>
											<TableCell sx={{ position: 'sticky', left: 0, zIndex: 1000, background: '#fff' }}>SUMM (USD)</TableCell>
											{allMonthsYears.map((monthYear) => (
												<TableCell key={monthYear} align="right">
													{monthlySumsUSD_FilteredData[monthYear] !== undefined ? monthlySumsUSD_FilteredData[monthYear].toFixed(2) : '0.00'} $
												</TableCell>
											))}
										</TableRow>
									)}
								</TableBody>
							</Table>
						</TableContainer>
						{filteredTableData.length === 0 && !loading && !error && tableData.length > 0 && (
                            <Typography sx={{ textAlign: 'center', padding: 3 }}>
                                No accounts match your search criteria.
                            </Typography>
                        )}
                         {tableData.length === 0 && !loading && !error && (
                            <Typography sx={{ textAlign: 'center', padding: 3 }}>
                                No data available.
                            </Typography>
                        )}
					</>
				)}
			</TablePage>
		</>
	);
};
